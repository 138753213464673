<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="locationItems"
            itemText="codeName"
            itemValue="code"
            name="locationName"
            label="위치"
            v-model="searchParam.locationName"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-card :title="`[${map.mapName}] 도면 정보`" class="cardClassDetailForm restFacility-card">
      <template slot="card-description">
        <q-badge color="red" text-color="white" label="도면이미지에 마우스 우클릭을 통해 휴게시설을 추가하세요." />
      </template>
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="LBL0000209" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div :style="`height: ${zoomer.height}px;`" class="zommer-container col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
          <panZoom v-if="mapExists" ref="panZoom" selector="#zoomerImage" :options="zoomer.options">
            <q-img
              id="zoomerImage"
              :src="map.mapSrc"
              :style="`width: ${(zoomer.height - 30) * map.ratio}px; height: ${zoomer.height - 30}px;`"
            >
              <q-menu
                touch-position
                context-menu
                @before-show="beforeShow"
              >
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="addInfo">
                    <q-item-section avatar class="restFacility-mark-add">
                      <q-icon name="add" color="red" />
                    </q-item-section>
                    <q-item-section>휴게시설</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
              <c-map-marker 
                v-for="(mark, idx) in map.marks"
                :key="idx"
                class="mark-restFacility"
                :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 25}px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                :isSelected="true">
                <template v-slot:innerMark>
                  <q-menu 
                    :ref="'markedproxy' + mark.idx" 
                    :content-class="'restFacility-menu-layer'"
                    anchor="top right"
                    self="top left">
                    <q-card 
                      flat bordered 
                      @click="openInfo(mark)"
                      :class="['restFacility-menu-card']">
                      <q-item class="restFacility-card-section restFacility-card-item">
                        <q-item-section>
                          <q-item-label>
                            <q-badge rounded color="light-green" :label="mark.deptName" />
                            <!-- <q-badge rounded color="light-blue" :label="mark.itemNo" />  -->
                          </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="deleteInfo(mark)" />
                        </q-item-section>
                      </q-item>
                      <q-card-section class="restFacility-card-section cursor-pointer">
                        <q-icon 
                          name="chevron_right" 
                          class="text-indigo" 
                        />
                        <b>휴게시설</b> : {{mark.locationName}} / {{ mark.restFacilitiesName }}
                      </q-card-section>
                      <q-card-section class="restFacility-card-section cursor-pointer">
                        <q-icon 
                          name="chevron_right" 
                          class="text-indigo" 
                        />
                        <b>휴게시설 개수</b> : {{mark.restFacilitiesCount}}
                      </q-card-section>
                      <q-card-section class="restFacility-card-section cursor-pointer">
                        <q-icon 
                          name="chevron_right" 
                          class="text-indigo" 
                        />
                        <b>진행중인 위생점검 년도</b> : {{mark.locationName}} / {{ mark.restFacilitiesName }}
                      </q-card-section>
                      <q-card-section class="restFacility-card-section cursor-pointer">
                        <span action="#" id="printJS-form">
                          <vue-qrcode
                            :value="serverName + '/sop/rtf/his/hygieneInspection?sopRestFacilitiesId=' + mark.sopRestFacilitiesId" 
                            :options="{ width: 180 }"
                            class="canvas"
                          >
                          </vue-qrcode>
                        </span>
                      </q-card-section>
                    </q-card>
                  </q-menu>
                </template>
              </c-map-marker>
            </q-img>
          </panZoom>
        </div>
        <div :style="`height: ${zoomer.height}px; overflow-y: auto;`" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 restFacility-map-list">
          <q-list bordered padding>
            <template v-for="(mapInfo, idx) in zoomer.maps">
              <q-item :key="idx" clickable @click="mapSelect(mapInfo)">
                <q-item-section class="q-ml-none">
                  <q-img
                    id="zoomerImage"
                    :src="mapInfo.mapSrc"
                    :style="`width: 150px; height: 150px;`"
                  >
                    <div class="absolute-bottom text-subtitle1 text-center">
                      {{mapInfo.mapName}}
                    </div>
                  </q-img>
                </q-item-section>
              </q-item>
              <q-separator v-if="(idx + 1) < zoomer.maps.length" :key="idx + 'sep'" spaced></q-separator>
            </template>
          </q-list>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import VueQrcode from '@chenfengyuan/vue-qrcode';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'restFacilitiesLayout',
  components: {
    VueQrcode
  },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        locationName: null,
      },
      zoomer: {
        height: 600,
        ratio: 1,
        style: 'width: 100%; height: 100%;',
        imageStyle: 'width: 90%; height: 90%;',
        value: 100,
        interval: 10,
        options: {
          minZoom: 0.5,
          maxZoom: 3,
          initialZoom: 1,
          initialX: 800,
          initialY: 200,
          bounds: true,
          boundsPadding: 0.1,
          zoomDoubleClickSpeed: 1, 
          onTouch: function(e) {
            return false;
          }
        },
        menu: {
          offsetX: 0,
          offsetY: 0,
        },
        currentMap: {
          sopMapId: ''
        },
        maps: [],
      },
      openMarkInfo: {},
      locationItems: [],
      serverName: '',
      editable: true,
      isSave: false,
      listUrl: '',
      locationListUrl: '',
      detailUrl: '',
      insertUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    map() {
      let map = {
        sopMapId: '',
        mapSrc: '',
        mapName: '',
        ratio: 0,
        marks: []
      }
      if (this.mapExists) {
        map = this.$_.find(this.zoomer.maps, { sopMapId: this.zoomer.currentMap.sopMapId })
      }
      return map;
    },
    mapExists() {
      return this.zoomer.maps && this.zoomer.maps.length > 0 && Boolean(this.zoomer.currentMap.sopMapId)
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.rtf.restFacilitiesLayout.list.url;
      this.locationListUrl = selectConfig.sop.rtf.restFacilities.location.list.url;
      this.detailUrl = selectConfig.sop.rtf.restFacilitiesLayout.get.url;
      this.insertUrl = transactionConfig.sop.rtf.restFacilitiesLayout.insert.url;
      this.deleteUrl = transactionConfig.sop.rtf.restFacilitiesLayout.delete.url;
      // code setting
      this.serverName = this.$store.getters.serverUrl
      this.getLocations();
      // list setting
      this.getList();
      this.setSize();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.zoomer, 'maps', _result.data)
        if (this.zoomer.maps && this.zoomer.maps.length > 0 
          && this.$_.findIndex(this.zoomer.maps, { sopMapId: this.zoomer.currentMap.sopMapId }) === -1) {
          this.$set(this.zoomer.currentMap, 'sopMapId', this.zoomer.maps[0].sopMapId)
        } else if (!this.zoomer.maps || this.zoomer.maps.length === 0) {
          this.$set(this.zoomer.currentMap, 'sopMapId', '')
        }
      },);
    },
    getLocations() {
      this.$http.url = this.locationListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd
      };
      this.$http.request((_result) => {
        this.locationItems = _result.data;
      },);
    },
    getDetail(sopMapId) {
      if (!sopMapId) return;
      this.$http.url = this.$format(this.detailUrl, sopMapId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        /**
         * 특정 지도 정보의 위치정보만 refresh
         */
        if (this.zoomer.maps && this.zoomer.maps.length > 0) {
          let idx = this.$_.findIndex(this.zoomer.maps, { sopMapId: sopMapId })
          this.$set(this.zoomer.maps[idx], 'marks', _result.data.marks)
        }
      },);
    },
    setSize() {
      this.zoomer.height = window.innerHeight - 200;
      this.zoomer.ratio = (this.zoomer.height - 30) / 575;
    },
    setStyle(mark) {
      // 너비, 높이가 30px임으로 중간을 맞추기 위해 15px만금 조정
      let style = `transform: translate(${mark.locationXcoordinate - 15}px, ${mark.locationYcoordinate - 15}px);`
        + `z-index: auto; user-select: auto; width: 30px; height: 30px;`
      return style;
    },
    beforeShow(event) {
      let ratio = 575 / (this.zoomer.height - 30)
      this.$set(this.zoomer.menu, 'offsetX', event.offsetX * ratio)
      this.$set(this.zoomer.menu, 'offsetY', event.offsetY * ratio)
    },
    mapSelect(mapInfo) {
      this.$set(this.zoomer.currentMap, 'sopMapId', mapInfo.sopMapId)
    },
    deleteInfo(mark) {
      this.$http.url = this.$format(this.deleteUrl, mark.sopRestFacilitiesLayoutResultId);
      this.$http.type = 'DELETE';
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getDetail(mark.sopMapId);
      },);
    },
    addInfo() {
      this.popupOptions.title = '휴게시설 검색'; // 유해위험기계기구 검색
      this.popupOptions.param = {
        type: 'single',
        mapFlag: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/rtf/restFacilitiesPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let item = data[0]
        this.$http.url = this.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = {
          hhmMapResultId: '',  // 작업위치 번호
          sopMapId: this.map.sopMapId,  // 지도 일련번호
          locationXcoordinate: this.zoomer.menu.offsetX,  // 작업위치 X좌표
          locationYcoordinate: this.zoomer.menu.offsetY,  // 작업위치 Y좌표
          sopRestFacilitiesId: item.sopRestFacilitiesId,  // 유해위험기계기구 일련번호
          regUserId: this.$store.getters.user.userId,  // 등록자 ID
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
    openInfo(mark) {
      this.openMarkInfo = mark
      // this.popupOptions.title = '휴게시설 상세';
      // this.popupOptions.param = {
      //   sopRestFacilitiesId: mark ? mark.sopRestFacilitiesId : '',
      // };
      // this.popupOptions.target = () => import(`${'./restFacilitiesDetail.vue'}`);
      if (!mark.sopRestFacilitiesHygieneInspectionId) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '해당 휴게시설에 진행중인 위생점검이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '휴게시설 위생점검표';
      this.popupOptions.param = {
        sopRestFacilitiesHygieneInspectionId: mark.sopRestFacilitiesHygieneInspectionId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/rtf/his/hygieneInspectionDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRestPopup;
    },
    closeRestPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail(this.openMarkInfo.sopMapId);
    },
  }
};
</script>
<style lang="sass">
.custom-zoomer
  border: solid 1px silver
  background-color: white !important
.mark-restFacility
  background: unset !important
  cursor: pointer
    
.restFacility-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.restFacility-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .restFacility-card-section
    padding: 0 10px 0 10px
  .restFacility-card-item
    min-height: auto

.zommer-container
  .vue-pan-zoom-scene
    overflow: auto

.restFacility-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important

.restFacility-map-list
  padding-right: 0 !important
  .q-item
    flex-direction: column
    align-items: center

.restFacility-mark-add
  min-width: 0 !important
  padding: 0 !important

.mark-restFacility
  background: unset !important
  cursor: pointer
</style>